<template>
  <div style="position: relative;">
	<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css ">
	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css">
  <router-view v-slot="{ Component }">
    <transition name="fullpage" style="overflow-y: hidden;">
      <component :is="Component" class="component" />
    </transition>
  </router-view>
  </div>
</template>

<script>

import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  title: 'StraightPath IT Management',
  name: 'App',
  components: {

  },
  created () {
    AOS.init()
  }
}
</script>

<style lang="scss">
.fullpage-enter-active,
.fullpage-leave-active{
  /* transition: opacity 5s, transform 5s, max-height 5s; */
  transition: all 2s;
}
.fullpage-enter{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.fullpage-leave{
  opacity: 1;
}
.fullpage-enter-to{
  opacity: 1;
}
.fullpage-leave-to{
  opacity: 1;
  transform: translate(-200%);
}
.fullpage-leave-active{
  position: absolute;
  top: 0;
  left: 0;
}
body{ /* Keeps weird scrollbar from showing up during translation */
  overflow-y: hidden;
}
/*
.slide-enter-to,
.slide-leave{
  opacity: 1;
  transform: translateY(25%);
}
*/
</style>
